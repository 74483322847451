<template>
    <div>
        <nav>
            <v-app-bar color="primary" app height="45%" >
                <v-app-bar-nav-icon  dark style="padding-left:1%;padding-bottom:0.5%"
                @click="drawer=!drawer"></v-app-bar-nav-icon>
            </v-app-bar>

            <v-navigation-drawer v-model="drawer" app class="primary">
                <v-list style="color:white;font:130% Arial;padding-top:10%;padding-left:5%;"
                dense
                nav>
                    <v-list-item 
                    v-for="link in links"
                    link 
                    :key="link.name"
                    :href="link.url" 
                    target="_blank">
                        <v-list-item-icon>
                           <i :class="link.icon"></i>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title style="color:white;">
                                {{link.name}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
        </nav>
    </div>
</template>

<script>
export default {
    data: () => ({
    drawer:false,
    links: [
        {name:'Hacer curriculum', url:'https://hacercurriculum.es/', icon:'fas fa-home'},
        {name:'Cartas de presentación', url:'https://hacercurriculum.es/', icon:'fas fa-columns'},
        {name:'Ejemplos', url:'https://hacercurriculum.es/ejemplos-de-curriculum/', icon:'far fa-copy'},
        {name:'Consejos', url:'https://hacercurriculum.es/consejos/', icon:'fas fa-check'},
    ],
  }),
}
</script>

<style>
i{
    color:white;
}
</style>