<template >
  <v-footer >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="link in links"
        :key="link.name"
        :href="link.url"
        target="_blank"
        color="#C4C4C6"
        text
        rounded
        class="my-2 caption"
      >
        {{ link.name }}
      </v-btn>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      links: [
        {name:'Condiciones generales', url:'https://hacercurriculum.es/condiciones-generales/'},
        {name:'Menciones legales', url:'https://hacercurriculum.es/menciones-legales/'},
        {name:'Política de cookies', url:'https://hacercurriculum.es/politica-de-cookies/'},
        {name:'Protección de datos', url:'https://hacercurriculum.es/proteccion-de-datos-personales/'},
      ],
    }),
  }
</script>

<style scoped>
.v-footer{
  background-color:#FEFEFE !important;
  border-color:#FEFEFE !important;
  padding-top:10px;
  position: absolute;
  left:0;
  bottom:0;
  width:100%;
}

@media only screen and (max-width: 600px) {
  .v-footer {
    position: relative;
  }
}
</style>