var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"homeContainer"}},[_c('tool-bar-home'),_c('div',{staticClass:"paddingH3em"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3","align":"right"}},[_c('v-avatar',[_c('v-img',{attrs:{"src":require("@/assets/logoCV.png")}})],1)],1),_c('v-col',{attrs:{"cols":"9","align":"left"}},[_c('h1',[_vm._v("Bienvenid@ a "),_c('span',{staticClass:"highlight"},[_vm._v("HACER CURRICULUM")])])])],1),_c('v-row',{attrs:{"align":"left"}},[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"9","align":"left"}},[_c('font',{attrs:{"size":"4"}},[_vm._v("Elige tú modelo de CV y descárgalo GRATIS")])],1)],1)],1),_c('div',[_c('carousel-3d',{attrs:{"controls-visible":true,"clickable":false,"height":465,"width":310,"perspective":0,"space":400}},_vm._l((_vm.arrayOfTemplates),function(slide,i){return _c('slide',{key:i,attrs:{"index":i},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var index = ref.index;
var isCurrent = ref.isCurrent;
var leftIndex = ref.leftIndex;
var rightIndex = ref.rightIndex;
return [_c('h3',[_vm._v(_vm._s(slide.name))]),_c('img',{class:{
              current: isCurrent,
              onLeft: leftIndex >= 0,
              onRight: rightIndex >= 0,
            },attrs:{"data-index":index,"src":slide.src}}),(isCurrent)?_c('div',{attrs:{"align":"center","id":"currentTemplate"}},[_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){return _vm.pulsarPlantilla(slide.name)}}},[_vm._v("Empezar")])],1):_vm._e()]}}],null,true)})}),1)],1),_c('footer-home')],1)}
var staticRenderFns = []

export { render, staticRenderFns }